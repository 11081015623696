import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { useHistory, NavLink } from 'react-router-dom'
import Button from './common/Button'
import Container from './common/Container'
import { useDeleteRequest } from '../hooks/request'
import { SIGNOUT } from '../urls'
import { signOut } from '../utils/auth'

export default function Layout({ children }) {
    const history = useHistory()
    const signout = useDeleteRequest({ url: SIGNOUT })

    function logOut() {
        signout.request({}, true)
        signOut(history)
    }

    return (
        <div className={cn('has-background-white-bis', css(styles.content))}>
            <nav style={{
                position: 'sticky',
                top: 0,
            }} className="navbar is-success" role="navigation" aria-label="main navigation">
                <Container>
                    <div className="navbar-menu">
                        <div className="navbar-start">
                            <p className="navbar-item">
                                <i className="icon ion-md-filing" />
                                <NavLink to="/rotation" className="has-text-white">Айланма</NavLink>
                            </p>
                            <p className="navbar-item">
                                <i className="icon ion-md-filing" />
                                <NavLink to="/income-tax" className="has-text-white">ЖШДС</NavLink>
                            </p>
                            <p className="navbar-item">
                                <i className="icon ion-md-list" />
                                <NavLink to="/clients" className="has-text-white">Мижозлар</NavLink>
                            </p>
                            <p className="navbar-item dropdown-item">
                                <i className="icon ion-md-calendar" />
                                <NavLink to="/qqc-monthly" className="has-text-white">ҚҚС Ойлик</NavLink>
                            </p>
                            <p className="navbar-item">
                                <i className="icon ion-md-calendar" />
                                <NavLink to="/qqc-quarterly" className="has-text-white">ҚҚС Кварталлик</NavLink>
                            </p>
                            <p className="navbar-item">
                                <i className="icon ion-md-calendar" />
                                <NavLink to="/income-quarterly" className="has-text-white">Фойда солиғи</NavLink>
                            </p>
                            <p className="navbar-item">
                                <i className="icon ion-md-paper-plane" />
                                <NavLink to="/telegram" className="has-text-white">Телеграм Бот</NavLink>
                            </p>
                        </div>
                        <div className="navbar-end navbar-item">
                            <p className="navbar-item">
                                <i className="icon ion-md-notifications-outline is-size-5" />
                            </p>

                            <Button icon="icon ion-md-log-out" className="button is-success navbar-item" text="Чиқиш"
                                onClick={logOut} />

                        </div>
                    </div>
                </Container>
            </nav>

            {children}

        </div>
    )
}
const styles = StyleSheet.create({
    content: {
        minHeight: '100vh',
    },
})
