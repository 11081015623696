import React from 'react'
import Layout from '../components/Layout'
import Button from '../components/common/Button'
import { useQueryParams } from '../hooks/queryString'
import { useModal } from '../hooks/modal'
import { useLoad } from '../hooks/request'
import { ROTATION_LIST } from '../urls'
import RotationItem from '../components/RotationItem'
import RotationCreate from '../components/RotationCreate'

export default function Home() {
    const params = useQueryParams()
    const report = useLoad({ url: ROTATION_LIST, params }, [params.page])
    const reportItem = report.response ? report.response.results : []

    const [showModal, hideModal] = useModal(
        <RotationCreate
            onSuccess={() => {
                hideModal()
                report.request()
            }}
            onCancel={() => hideModal()} />,
    )

    return (
        <Layout>
            <div className="columns mt-3 mr-3">
                <div className="column is-capitalized ml-4">
                    <span className="is-size-4">Айланма:</span>
                </div>
                <div className="column">
                    <Button
                        icon="ion-md-calendar"
                        text="Янги ой қўшиш"
                        onClick={showModal}
                        className="is-pulled-right is-success is-outlined"
                    />
                </div>
            </div>
            <div className="columns is-multiline mr-3 ml-3 mt-3">
                {reportItem.map((elem) => (
                    <div className="column is-3">
                        <RotationItem
                            key={reportItem.id}
                            onUpdate={report.request}
                            onDelete={report.request}
                            reportItem={elem} />
                    </div>
                ))}
            </div>
        </Layout>
    )
}
