import React from 'react'
import Layout from '../components/Layout'
import Button from '../components/common/Button'
import { useQueryParams } from '../hooks/queryString'
import { useModal } from '../hooks/modal'
import { useLoad } from '../hooks/request'
import { QQC_MONTHLY_LIST } from '../urls'
import QqcMonthlyCreate from '../components/QqcMonthlyCreate'
import QqcMonthlyItem from '../components/QqcMonthlyItem'

export default function QqcMonthly() {
    const params = useQueryParams()
    const qqcMonthly = useLoad({ url: QQC_MONTHLY_LIST, params }, [params.page])
    const reportItem = qqcMonthly.response ? qqcMonthly.response.results : []

    const [showModal, hideModal] = useModal(
        <QqcMonthlyCreate
            onSuccess={() => {
                hideModal()
                qqcMonthly.request()
            }}
            onCancel={() => hideModal()} />,
    )

    return (
        <Layout>
            <div className="columns mt-3 mr-3">
                <div className="column is-capitalized ml-4">
                    <span className="is-size-4">ҚҚС Ойлик:</span>
                </div>
                <div className="column">
                    <Button
                        icon="ion-md-calendar"
                        text="Янги ой қўшиш"
                        onClick={showModal}
                        className="is-pulled-right is-success is-outlined"
                    />
                </div>
            </div>
            <div className="columns is-multiline mr-3 ml-3 mt-3">
                {reportItem.map((elem) => (
                    <div className="column is-3">
                        <QqcMonthlyItem
                            key={reportItem.id}
                            onUpdate={qqcMonthly.request}
                            onDelete={qqcMonthly.request}
                            reportItem={elem} />
                    </div>
                ))}
            </div>

        </Layout>
    )
}
