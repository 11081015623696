import React from 'react'
import isEmpty from 'lodash/isEmpty'
import * as queryString from 'query-string'
import cn from 'classnames'
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile'
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet'
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn'
import Button from '../components/common/Button'
import FinanceCreate from '../components/FinaceCreate'
import { useModal } from '../hooks/modal'
import { useQueryParams } from '../hooks/queryString'
import { useLoad, useSortTableData } from '../hooks/request'
import { FINANCE_LIST } from '../urls'
import FinanceItem from '../components/FinanceItem'
import Layout from '../components/Layout'
import TransactionSearch from '../components/TransactionSearch'
import Table from '../components/common/Table'

export default function Finance({ history }) {
    const params = useQueryParams()
    const finance = useLoad({ url: FINANCE_LIST, params }, [params.page])
    const { items, requestSort, sortConfig } = useSortTableData(finance.response ? finance.response.results : [])
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return
        }
        return (
            sortConfig.key === name ? sortConfig.direction : undefined
        )
    }
    const [showModal, hideModal] = useModal(
        <FinanceCreate
            onSuccess={() => {
                hideModal()
                finance.request()
            }}
            onCancel={() => hideModal()} />,
    )

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ search })}`)
    }
    return (
        <Layout>
            <div className="columns">
                <div className="column ml-3 mt-3 mb-3">
                    <TransactionSearch onSearch={onSearch} />
                </div>
                <div className="column mt-3 mr-3 is-flex">
                    <div className="is-pulled-right is-success is-outlined mr-3">
                        <ExcelFile
                            element={(
                                <Button
                                    icon="ion-md-download"
                                    text="Excel"
                                    className="is-pulled-right is-success is-outlined mr-3" />
                            )}
                            filename="Мижозлар">
                            <ExcelSheet name="Excel" data={finance.response ? finance.response.results : []}>
                                <ExcelColumn label="Корхона номи" value="name" />
                                <ExcelColumn label="Хисоб рақам" value="accountNumber" />
                                <ExcelColumn label="Корхона рахбари" value="directorName" />
                                <ExcelColumn label="Солиқ тури" value="type" />
                                <ExcelColumn label="Шартнома суммаси" value="contractSum" />
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                    <Button
                        icon="ion-md-search"
                        text="Корхона номи"
                        className={cn(getClassNamesFor('name'),
                            'is-pulled-right is-success is-outlined mr-3')}
                        onClick={() => requestSort('name')} />
                    <Button
                        icon="ion-md-search"
                        text="Корхона стири"
                        onClick={() => requestSort('stir')}
                        className={cn(getClassNamesFor('stir'),
                            'is-pulled-right is-success is-outlined mr-3')} />
                    <Button
                        icon="ion-md-search"
                        text="Рахбари стири"
                        onClick={() => requestSort('directorStir')}
                        className={cn(getClassNamesFor('directorStir'),
                            'is-pulled-right is-success is-outlined mr-3')} />
                    <Button
                        icon="ion-md-search"
                        text="Сумма"
                        onClick={() => requestSort('contractSum')}
                        className={cn(getClassNamesFor('contractSum'),
                            'is-pulled-right is-success is-outlined mr-3')} />
                    <Button
                        icon="ion-md-add"
                        onClick={showModal}
                        text="Янги мижоз қўшиш"
                        className="is-pulled-right is-success is-outlined"
                    />
                </div>
            </div>
            <div className="columns ml-3 mr-3" style={{ overflowX: 'scroll' }}>
                <Table
                    loading={finance.loading}
                    items={items}
                    columns={{
                        id: '№',
                        name: 'Корхона номи',
                        stir: 'Корхона стири',
                        directorName: 'Корхона рахбари',
                        directorStir: 'Рахбари стири',
                        bank: 'Банк номи',
                        accountNumber: 'Хисоб рақам',
                        mfo: 'МФО',
                        phoneNumber: 'Оператор телефони',
                        inspector: 'Солиқ инспектори',
                        login: 'Банк логин',
                        type: 'Солиқ_тури',
                        taxDate: 'ҚҚСга_ўтган_ой',
                        contractSum: 'Шартнома суммаси',
                        first: 'ЭРИ(Корхона)',
                        second: 'ЭРИ(Раҳбар)',
                        ad: '',
                        ads: '',

                    }}
                    renderItem={(item, index) => (
                        <FinanceItem
                            key={item.id}
                            index={index}
                            onUpdate={finance.request}
                            onDelete={finance.request}
                            item={item} />
                    )} />

            </div>
        </Layout>
    )
}
